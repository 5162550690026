import React, { ReactElement } from "react";

import { useBreakpoint } from "hooks";
import useIsInEditMode from "hooks/useIsInEditMode";
import {
    addEditAttributes,
    addEditAttributesBlockLevel,
} from "utils/episerver";

import { Container, Heading, Text, Check } from "./UniqueSellingPoint.styled";
import UniqueSellingPointListProps from "./UniqueSellingPointProps";

const UniqueSellingPointList = ({
    identifier,
    heading,
    text,
    contentId,
}: UniqueSellingPointListProps): ReactElement => {
    const isInEditMode = useIsInEditMode();

    let icon: string;
    switch (useBreakpoint()) {
        case "XL":
            icon = "check140";
            break;
        case "L":
            icon = "check105";
            break;
        case "M":
            icon = "check105";
            break;
        default:
            icon = "check70";
    }

    return (
        <Container id={identifier} {...addEditAttributesBlockLevel(contentId)}>
            <Check key={`Check-${icon}`} icon={icon} />
            {(heading || isInEditMode) && (
                <Heading
                    {...addEditAttributes("Heading")}
                    dangerouslySetInnerHTML={{
                        __html: heading || "",
                    }}
                />
            )}

            <Text {...addEditAttributes("Text")}>{text || ""}</Text>
        </Container>
    );
};

export default React.memo(UniqueSellingPointList);
